import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    usecaseWrapper: {
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        border: 'solid 1px #c9dbf7',
        height: 128,
        borderRadius: '0 15px 15px 0',
        '&:hover $usecaseImage': {
            opacity: 0.5,
        },
        '&:hover $usecaseTitle': {
            color: theme.palette.logo.purple.main,
        },
    },
    usecaseImage: {
        display: 'flex !important',
        height: 128,
        transition: 'all 0.7s',
        boxShadow: '5px 3px 6px 0 rgba(0, 0, 0, 0.16)',
        background: '#ececec',
    },
    usecaseTitleWrapper: {
        flexGrow: 1,
        padding: theme.spacing(5.5),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2.5),
        },
    },
    usecaseTitle: {
        display: 'block',
    },
    seeMore: {
        width: 128,
        height: 128,
        fontSize: 36,
        color: theme.palette.primary.dark,
    },
}));
